import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../layouts/default"

import Box from "../../components/box"
import Breadcrumb from "../../components/breadcrumb"
import Column from "../../components/column"
import Columns from "../../components/columns"
import Constrain from "../../components/constrain"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import NoBreak from "../../components/no-break"
import PageTitle from "../../components/page-title"
import RotateLetter from "../../components/rotate-letter"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import VideoPlayer from "../../components/video-player"

const Step = ({ step, title, children }) => {
  return (
    <Box bg="muted" p={[6, 6, 8]}>
      <Columns collapse={[true, false]}>
        <Column width="content">
          <RotateLetter size={12} fontSize={6}>
            {step}
          </RotateLetter>
        </Column>
        <Column>
          <Stack space={6}>
            <Heading as="h3" level={4}>
              {title}
            </Heading>
            {children}
          </Stack>
        </Column>
      </Columns>
    </Box>
  )
}

const WorkshopHipHop = () => {
  const data = useStaticQuery(graphql`
    query WorkshopHipHopWeitermachenQuery {
      poster: file(relativePath: { eq: "videos/poster-spax-weiter.jpg" }) {
        ...largeImage
      }
    }
  `)

  return (
    <Layout backdrop="workshop-hiphop-weiter">
      <Seo
        title="Weitermachen, mit rappen"
        description="In der Fortsetzung unseres HipHop-Tutorials für Jugendliche und junge Erwachsene ab 15 Jahren führt der Rapper Spax in seine Arbeitsprozesse ein und verrät, mit welchen Kniffen welche Effekte erzielt werden können."
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Weitermachen, mit rappen",
              link: "/workshops/weitermachen-mit-rappen",
            },
          ]}
        />
        <PageTitle>
          Weitermachen, mit rappen – ein <NoBreak>Tutorial</NoBreak> von Spax
        </PageTitle>
        <Heading as="h2" level={5}>
          Workshop für Jugendliche und junge Erwachsene ab 15 Jahren
        </Heading>
        <Paragraph dropcap={true}>
          Ihr habt eine Idee für einen Rap-Song und wollt nun so richtig
          loslegen? In dieser Fortsetzung unseres HipHop-Tutorials nimmt euch
          der Rapper Spax mit hinein in seinen Arbeitsprozess. Er zeigt euch
          seine Werkzeuge und verrät, mit welchen Kniffen welche Effekte erzielt
          werden können. Er erklärt, wie ihr eure ›vocals‹ durch Betonungen,
          Takt, Tempo und Rhythmus in Sprechgesang verwandelt. Und vor allem:
          Wann und wie der Beat ins Spiel kommt.
        </Paragraph>

        <VideoPlayer src="/workshops/spax-hiphop-weiter" poster={data.poster} />

        <Constrain>
          <Stack>
            <Heading as="h2" level={3}>
              Die 7 wichtigsten Schritte in der Übersicht
            </Heading>
            <Stack space={3}>
              <Step
                step={1}
                title="Mit welchen Werkzeugen möchtest du arbeiten?"
              >
                <Paragraph>
                  Schreibst du deinen Rap-Text auf Papier? Oder lieber digital
                  auf dem Computer oder Smartphone? Du hast die freie Wahl!
                </Paragraph>
                <Paragraph>
                  Wichtig ist, dass dir deine Werkzeuge die Möglichkeit geben,
                  deine gesammelten Ideen beim Ausprobieren auch noch einmal neu
                  zu ordnen. Denn wer schreibt schon auf Anhieb den perfekten
                  Song?
                </Paragraph>
              </Step>
              <Step step={2} title="Wie wird aus dem Text Musik?">
                <Paragraph>
                  Rap ist Sprechgesang. Das heißt: Es geht ums Sprechen!
                </Paragraph>
                <Paragraph>
                  Durch deine Aussprache, deine Betonungen und deine
                  Sprechgeschwindigkeit kannst du dem Text einen Rhythmus
                  verleihen und ihn zu Musik werden lassen.
                </Paragraph>
              </Step>
              <Step step={3} title="Wodurch bekommt der Rap-Text seinen Takt?">
                <Paragraph>
                  Die Anzahl der Silben in einer Zeile (oder auch: ›line‹)
                  verleiht deinem Text einen ganz bestimmten Takt.
                </Paragraph>
                <Paragraph>
                  Dieses Taktgefüge gibt das Timing und den Rhythmus für deinen
                  Rap-Song vor. Auf die Anzahl Silben kommt es also an!
                </Paragraph>
                <Paragraph>
                  Du kannst deine Sätze so formulieren, dass jede line aus einem
                  einzigen Satz besteht (= Zeilenstil). Du kannst deine Sätze
                  aber auch über mehrere lines laufen lassen. Je nachdem, ob du
                  möchtest, dass der Inhalt sehr schnell oder erst über mehrere
                  Zeilen hinweg erfasst wird.
                </Paragraph>
              </Step>
              <Step step={4} title="Wie entsteht Rhythmus?">
                <Paragraph>
                  Der Takt ist auch wichtig für den Rhythmus deines Raps. Beides
                  hängt eng zusammen.
                </Paragraph>
                <Paragraph>
                  Um einen gleichmäßigen Takt zu erhalten, sollten die lines
                  immer in etwa die gleiche Anzahl an Silben enthalten.
                </Paragraph>
                <Paragraph>
                  Beim Schreiben geht es also auch darum, deine lines so zu
                  formulieren, dass sie zum Takt passen.
                </Paragraph>
                <Paragraph>
                  Im Takt zu bleiben, ist die große Kunst des Raps. Dabei gibt
                  es aber ganz unterschiedliche Umsetzungen: Ob du deine lines
                  melodisch gesungen oder eher im staccato (also stoßartig)
                  aussprichst, ist eine Frage des Styles und bleibt ganz dir
                  überlassen.
                </Paragraph>
              </Step>
              <Step step={5} title="Wann und wie kommt der Beat ins Spiel?">
                <Paragraph>
                  Manche Rapper schreiben ihren Text direkt auf den Beat. Andere
                  machen sich zunächst ans Schreiben und suchen anschließend
                  nach dem passenden Beat für ihren Text. Beides ist möglich.
                </Paragraph>
                <Paragraph>
                  Wichtig ist, dass der Beat zur Message deines Rap-Textes
                  passt. Je nachdem, ob es darin relativ harmonisch oder
                  ziemlich aggressiv zugeht, kannst du dazu einen melodischeren
                  oder einen harten Beat mit viel Power auswählen. Du kannst
                  aber auch ganz bewusst mit Widersprüchlichkeiten spielen.
                </Paragraph>
                <Paragraph>
                  Einen Beat kann man im Internet suchen. Zum Beispiel auf der
                  Website{" "}
                  <TextLink
                    href="https://www.allroundabeats.com"
                    rel="nofollow"
                  >
                    www.allroundabeats.com
                  </TextLink>
                  . Wichtig ist, dass du dabei das Urheberrecht beachtest.
                </Paragraph>
                <Paragraph>
                  Du kannst dir deinen Beat aber auch selbst bauen. Zum Beispiel{" "}
                  <TextLink
                    href="https://www.elf-audio.com/koala/"
                    rel="nofollow"
                  >
                    mit der App ›Koala‹
                  </TextLink>
                  . In der funk-Reihe{" "}
                  <TextLink
                    href="https://www.funk.net/channel/soundsof-12144"
                    rel="nofollow"
                  >
                    ›Sounds of‹
                  </TextLink>{" "}
                  kannst du dir bei den Profis so einiges dazu abschauen.
                </Paragraph>
              </Step>
              <Step
                step={6}
                title="Wie entwickelt sich dabei ein eigener Stil?"
              >
                <Paragraph>
                  Im Rap gibt es unzählig viele verschiedene Stile. Zum Beispiel
                  Concious-Rap, Trap, Emo, G-Funk und und und...
                </Paragraph>
                <Paragraph>
                  Bei der Entwicklung deines eigenen Stils kommt es ganz darauf
                  an, ob du eher den Inhalt (die ›vocals‹) oder eher die
                  Technik, die Aussprache und das Musikalische in den
                  Vordergrund stellen willst.
                </Paragraph>
                <Paragraph>
                  Und es kommt darauf an, welche Stimmung du damit verbreiten
                  möchtest. Durch Q&As (= Questions & Answers wie »Seid ihr noch
                  da?« – »Ja!«) kannst du dein Publikum aktivieren und zum
                  mitrappen anregen.
                </Paragraph>
              </Step>
              <Step
                step={7}
                title="Wie kannst du deinen Rap aufnehmen und mit dem Beat zusammenbringen?"
              >
                <Paragraph>
                  Wenn du den ersten Entwurf für deinen Text fertiggestellt
                  hast, geht es ans Ausprobieren. Es muss nicht von Anfang an
                  alles sitzen und perfekt sein!
                </Paragraph>
                <Paragraph>
                  Am Computer oder Smartphone kannst du ein Demo aufnehmen. Das
                  geht zum Beispiel einfach mit einer Sprachaufnahme.{" "}
                </Paragraph>
                <Paragraph>
                  Dazu kannst du dann den passenden Beat darunterlegen. Dafür
                  gibt es verschiedene Aufnahme-Programme wie Logic, Cubase,
                  Samplitude oder Fruity Loops. Dabei kannst du den Beat und den
                  Text auch noch ein wenig anpassen, damit beide perfekt
                  aufeinander abgestimmt sind.
                </Paragraph>
                <Paragraph>
                  Du musst deinen Rap-Song nicht als One-Take in einem Zug
                  aufnehmen. Du kannst auch Schritt für Schritt vorgehen und
                  mehrere Aufnahmen machen, aus denen du dir am Ende das Beste
                  heraussuchst.
                </Paragraph>
                <Paragraph>
                  Zuletzt gehts dann ans Mixen und Mastern. Auch hier kannst du
                  vieles Ausprobieren und mit Effekten spielen. Wichtig ist vor
                  allem, dass du keine Angst hast, sondern einfach loslegst –
                  learning by doing!
                </Paragraph>
              </Step>
            </Stack>
          </Stack>
        </Constrain>
      </Stack>
    </Layout>
  )
}

export default WorkshopHipHop
